$(document).ready(function() {

	setDeliveryRequired();
	/*
	 * Parse youtube url
	 */
	$('.swipebox').youtubeParse();

	/**
	 * Init swipebox - lightbox
	 */
    $('.swipebox').swipebox({
    	
    });

	// Check cookie bar
	if( !$.cookie('cookie-accepted') ) {
		$('.cookie').show();
	}

	// Hide cookie bar
	$('body').on('click', '.cookie .btn', function(event) {
		event.preventDefault();

		$(this).closest('.cookie').fadeOut('slow');
		$.cookie('cookie-accepted', true);
	});

	/*
	 * Init chosen
	 */
	$(".chosen").chosen({
		width: "100%",
		placeholder_text_multiple: 'Kérem válasszon'
	});

	// init menu
	initMenu();
	initMenuClicks();
	checkMenu();
	$(window).resize(function(event) {
		checkMenu();
	});

	function setDeliveryRequired()
	{
		var is_checked = $('input#needs_delivery').prop('checked');

		if(is_checked === true) {
			$('input#delivery_address').attr('required', 'required');
		} else if(is_checked === false) {
			$('input#delivery_address').removeAttr('required');
		}

	}

	function numberFormat(x) 
	{
    	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	}

	function getModels(manufacturer_id) 
	{
		var submit_button = $("#search-submit");
		if(manufacturer_id === "#") {
			var options = '<option value="#">Típus:</option>';
			submit_button.attr('disabled', 'disabled');			
			$("#model-select").html(options);			
			$("#model-select").trigger('chosen:updated');	
		} else {
			submit_button.removeAttr('disabled');
			$.ajax({
				url: '/api/product-models',
				type: 'GET',
				dataType: 'JSON',
				data: {id: manufacturer_id},
			})
			.done(function(models) {
				var count = models.length;
				var options = '<option value="#">Típus: ('+count+' db)</option>';
				$.each(models, function(index, model) {
					options += '<option value="'+model.model+'">'+model.model+'</option>';
				});
				$("#model-select").html(options);
				$("#model-select").trigger('chosen:updated');	
			});
		}
	}

	function setCartCount(count)
	{
		//var cart_icon = '<i class="fa fa-shopping-cart"></i>'
		if(count < 1) {
			$('span.cart-counter').text("");
		} else {
			$('span.cart-counter').text("("+count+")");
		}
	}

	getModels($('#manufacturer-select').val());


	// facebook | API version 2.5
	(function(d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "//connect.facebook.net/hu_HU/sdk.js#xfbml=1&version=v2.5";
		fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));


	// get models by manufacture selected
	$('body').on('change', '#manufacturer-select', function(e) {
		getModels($(this).val());
	});	

	// adding items to cart
	$('body').on('click', '.add-to-cart', function(e) {
		e.preventDefault();
		var unique_id = $(this).data('id');
		var amount = $(this).siblings('input').val();
		if(typeof amount === "undefined") amount = 1;
		$.ajax({
			url: '/api/add-to-cart',
			type: 'POST',
			dataType: 'JSON',
			data: {unique_id: unique_id, amount: amount
			},
		})
		.done(function(data) {
			setCartCount(data.cart_count);
			setNotify(data.type, data.message);
		});									
	});
	// modify items in cart
	$('body').on('click', '.cart-modify', function(e) {
		
		$.ajax({
			url: '/api/modify-cart',
			type: 'POST',
			dataType: 'JSON',
			data: {unique_id: unique_id, amount: amount},
		})
		.done(function(data) {
			var price = "Ár "+data.price+" HUF";
			var sum = "Összesen "+data.sum+" HUF";
			button.siblings('p.price').html(price);
			$(".sum").html(sum);
			setCartCount(data.cart_count);
			setNotify(data.response.type, data.response.message);
		});		
	});

	// removing from cart
	$('body').on('click', '.cart-remove', function(e){
		e.preventDefault();
		var button = $(this);
		var unique_id = button.data('id');
		button.closest('tr').hide();
		$.ajax({
			url: '/api/remove-cart',
			type: 'POST',
			dataType: 'JSON',
			data: {unique_id: unique_id},
		})
		.done(function(data) {
			var net = data.sum
			var sum = parseInt(net * 1.27);
			$("#price-net").html("Nettó <span>"+numberFormat(net)+" HUF</span>");
			$("#price-vat").html("Áfa <span>"+numberFormat((sum-net))+" HUF</span>");
			$("#price-sum").html("Összesen <span>"+numberFormat(sum)+" HUF</span>");
			setNotify(data.response.type, data.response.message);
			setCartCount(data.cart_count);
			if (net == 0) {
				$("input#order-send").attr('disabled', 'disabled');
			} else {
				$("input#order-send").removeAttr('disabled');
			}
		})
		.fail(function() {
			console.log("error");
		})
		.always(function() {
			console.log("complete");
		});		
	});

	// cart input only-number allowing
	$('body').on('keydown', 'input.cart-amount', function(e){
		if($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 || (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) || (e.keyCode >= 35 && e.keyCode <= 40)) {
			return;
		}
		if((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
			e.preventDefault();
		}
	});

	$('body').on('keydown', 'input.product-amount', function(e) {
		if($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 || (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) || (e.keyCode >= 35 && e.keyCode <= 40)) {
			return;
		}
		if((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
			e.preventDefault();
		}
	});

	$('body').on('change', 'input#needs_delivery', function(e) {
		setDeliveryRequired();
	});

	function modifyCart(input) {
		var input = input;
		var amount = parseInt(input.val());
		var unique_id = input.data('id');
		if(amount == 0) {
			input.val(1);
			amount = 1;
		}
		$.ajax({
			url: '/api/modify-cart',
			type: 'POST',
			dataType: 'JSON',
			data: {unique_id: unique_id, amount: amount},
		})
		.done(function(data) {
			var price = numberFormat(data.price)+" HUF";
			var net = data.sum
			var sum = parseInt(net * 1.27);
			input.closest('.cart_quantity').siblings('td.cart_total').find('p.cart_total_price').html(price);
			$("#price-net").html("Nettó <span>"+numberFormat(net)+" HUF</span>");
			$("#price-vat").html("Áfa <span>"+numberFormat((sum-net))+" HUF</span>");
			$("#price-sum").html("Összesen <span>"+numberFormat(sum)+" HUF</span>");
			setCartCount(data.cart_count);
			setNotify(data.response.type, data.response.message);
		});
	}
	// cart modify
	$('body').on('change', 'input.cart-amount', function(e) {
		modifyCart($(this));
		/*	*/
	});

	$('body').on('click', '.cart_quantity_up', function(e) {
		e.preventDefault();
		var input = $(this).siblings('input.cart-amount');
		var val = parseInt(input.val());
		input.val(++val);
		modifyCart(input);
	});

	$('body').on('click', '.cart_quantity_down', function(e) {
		e.preventDefault();
		var input = $(this).siblings('input.cart-amount');
		var val = parseInt(input.val());
		if(val == 1) return;
		input.val(--val);
		modifyCart(input);
	});

	/*// delivery_address field hidden/shown
	$('body').on('change', '#needs_delivery', function(e) {
		e.preventDefault();
		if($(this).is(":checked")) {
			$('#delivery-address-row').show();
		}
		else {
			$('#delivery-address-row').hide();
		}
	});*/
});